// const BASE_URL = "https://whale-app-rqcs6.ondigitalocean.app/api";
// const BASE_URL = 'https://lobster-app-5gysu.ondigitalocean.app/api'
//  const BASE_URL = 'https://tencentt4466.tech/api'
// const BASE_URL = 'http://localhost:8000/api'
const BASE_URL = 'https://seashell-app-ui95i.ondigitalocean.app/api'
// const BASE_URL = 'https://shophuawei009.site/api'
export const upiurl = 'https://www.indopay-stc.online/pay'
export const upiurl2 = 'https://www.sun-pay.site/pay'

export default BASE_URL;

//https://stingray-app-myegm.ondigitalocean.app/api
//http://64.227.128.113/api
//https://sstonebats.com/api/
//https://whale-app-rqcs6.ondigitalocean.app/api

